import React from "react";
import '../../components/Layout/layoutGlossary.css';

const BulletPoints = ({ children }) => {
  return (
    <li className="bullet-points">
      {children}
    </li>
  );
};

export default BulletPoints;
